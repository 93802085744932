import React, { useEffect, useState } from "react";
import "./header.css";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../assets/image/logo.png";
import { api } from "../../api/api";
import CustomPopup from "../CustomPopup/CustomPopup";
import CustomInput from "../common/Input/Input";
import { useForm } from "react-hook-form";
import PhoneInput from "react-phone-input-2";
import CheckBox from "../common/Checkbox/Checkbox";

function Header() {
  const location = useLocation();
  const [show, setShow] = useState(false);
  const [value, setValue] = useState("");
  const [phone, setPhone] = useState("");
  const [countryCode, setCountryCode] = useState("");
  const [fullPhone, setFullPhone] = useState("");
  const [categoriesList, setCategoriesList] = useState([]);
  const [isSticky, setIsSticky] = useState(false);

  const handlePhoneChange = (value, country) => {
    const dialCode = country.dialCode; // Get dial code

    setFullPhone(value);
    setCountryCode(country?.dialCode);

    const phoneStr = `+${value}`;
    const codeStr = `+${dialCode}`;

    // Check if the phone number starts with the code and remove it
    if (phoneStr.startsWith(codeStr)) {
      setPhone(phoneStr.slice(codeStr.length)); // Remove the code from the phone number
    } else {
      // Return the phone number unchanged if it doesn't start with the code

      setPhone(phoneStr); // Remove the code from the phone number
    }
  };
  const {
    register,
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const registerOptions = {
    name: { required: "name required" },
    email: { required: "required" },
    dreamCompany: { required: "required" },
    companyName: { required: "required" },
    experience: { required: "required" },
  };
  const handelError = (error) => {
    console.log(error);
  };
  const handleScroll = () => {
    if (window.scrollY > 160) {
      // Adjust the scroll position where the header becomes sticky
      setIsSticky(true);
    } else {
      setIsSticky(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleRegistration = async (data) => {
    console.log(data, "data");
    var payload = {
      fullName: data?.name,
      emailId: data?.email,
      phoneNumber: `+${countryCode}${phone}`,
      companyName: data?.companyName,
      experienceInYears: data?.experience,
      dreamCompany: data?.dreamCompany,
      topicsOfInterest: data?.Interest,
      occupation: "Software Engineer",
    };
    let res = await api(
      `api/v1/application/create`,
      payload,
      "postWithoutToken",
      "",
      "Send Successfully",
      ""
    );
    if (res && res.success) {
      // setPetTypeData(res?.data);
      console.log(res.data, res, "card");
      setShow(!show);
      reset();
    }
  };

  const headerMenu = [
    {
      title: "Home",
      path: "/",
      subMenu: false,
    },
    {
      title: "Programs",
      path: "#",
      subMenu: true,
      subMenu: [
        {
          title: "NextGen Academy",
          path: "/NextGen-Academy",
          subMenu: false,
        },
        {
          title: "Talent Hub",
          path: "/talent-hub",
          subMenu: false,
        },
        {
          title: "Skill Sprint 5.0",
          path: "/skill-sprint",
          subMenu: false,
        },
      ],
    },
    {
      title: "vvGrad Abroad",
      path: "/vvgard-abroad",
      subMenu: false,
    },
    {
      title: "Prepare Job",
      path: "#",
      subMenu: false,
    },
    {
      title: "Hire with Us",
      path: "#",
      subMenu: false,
    },

    {
      title: "Contact Us",
      path: "/contact-us",
      subMenu: false,
    },
  ];
  const getHeaderStyle = () => {
    switch (location.pathname) {
      case "/talent-hub":
        return "header_white";
      case "/skill-sprint":
        return "header_white";
      default:
        return "";
    }
  };

  return (
    <>
      <header className={isSticky ? "sticky-menu" : ""}>
        <div className="container">
          <nav
            className={`navbar navbar-expand-lg navbar-light p-0 ${getHeaderStyle()}`}
          >
            <Link className="navbar-brand" to="/">
              <img src={Logo} alt="" />
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mb-2 mb-lg-0">
                {headerMenu?.map((item, index) => {
                  return (
                    <li className="nav-item">
                      <Link
                        className={`${
                          location.pathname === item.path ? "active" : ""
                        } navbar_link`}
                        to={item.path}
                        id={item.title}
                        role="button"
                      >
                        {item.title}{" "}
                        {item.subMenu && <i className="fas fa-angle-down"></i>}
                      </Link>

                      {item.subMenu && (
                        <ul
                          className="dropdown-menu sub_first"
                          aria-labelledby={item.title}
                        >
                          {item.subMenu &&
                            item.subMenu?.map((subFirst, index) => {
                              return (
                                <li className="nav-item dropdown dropdown-item">
                                  <Link
                                    className={`${
                                      location.pathname === "service"
                                        ? "active"
                                        : ""
                                    } navbar_link `}
                                    to={subFirst.path}
                                    id={subFirst.title}
                                    role="button"
                                  >
                                    {subFirst.title}{" "}
                                    {subFirst.subMenu && (
                                      <i class="fas fa-angle-right"></i>
                                    )}
                                  </Link>

                                  {subFirst.subMenu && (
                                    <ul
                                      className="dropdown-menu sub_sec"
                                      aria-labelledby={subFirst.title}
                                    >
                                      {subFirst.subMenu &&
                                        subFirst.subMenu?.map(
                                          (subSec, index) => {
                                            return (
                                              <li>
                                                <Link
                                                  className={`${
                                                    location.pathname ===
                                                    "service"
                                                      ? "active"
                                                      : ""
                                                  } navbar_link dropdown-item`}
                                                  to=""
                                                >
                                                  {subSec.title}
                                                </Link>
                                              </li>
                                            );
                                          }
                                        )}
                                    </ul>
                                  )}
                                </li>
                              );
                            })}
                        </ul>
                      )}
                    </li>
                  );
                })}
              </ul>
            </div>

            <div className="call_box d-flex">
              <button className="btn_primary" onClick={() => setShow(!show)}>
                Join Now <i class="fas fa-arrow-right"></i>
              </button>
              {/* <a href="#" target="_self" class="btn_primary ">
              Try for free <i class="icon-4"></i>
            </a> */}
            </div>
          </nav>
        </div>
      </header>

      <CustomPopup size={"lg"} show={show} close={setShow} title="">
        <div className="apply_form">
          <div className="second_heading">
            Apply <span className="secondary_text"> Now</span>
          </div>{" "}
          <div className="sub_text">Next Batch Start in February</div>
          <div className="form_fields  mt-5">
            <form onSubmit={handleSubmit(handleRegistration, handelError)}>
              <div className="row">
                <div className="col-md-6">
                  <CustomInput
                    register={register}
                    label=""
                    name="name"
                    placeholder="Candidate Full Name"
                    type="text"
                    errors={errors.name}
                    validation={registerOptions.name}
                  />
                </div>
                <div className="col-md-6">
                  <CustomInput
                    register={register}
                    label=""
                    name="email"
                    placeholder="Email Id"
                    type="text"
                    errors={errors.email}
                    validation={registerOptions.email}
                  />
                </div>
                <div className="col-md-6">
                  <div className="form-group">
                    {/* <label htmlFor="">phone</label> */}
                    <div className=" number_input mb-3">
                      {/* <img className="icon" src={business_icon} /> */}
                      <PhoneInput
                        country={"in"}
                        value={value}
                        label=""
                        className="input_fields ps-0"
                        // onChange={(number) => setValue(number)}
                        onChange={(value, country) =>
                          handlePhoneChange(value, country)
                        }
                        // onChange={(number, country) => getNum(number, country)}
                      />

                      {/* <PhoneNumberInputGroup /> */}
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <CustomInput
                    register={register}
                    label=""
                    name="companyName"
                    placeholder="Company Name"
                    type="text"
                    errors={errors.companyName}
                    validation={registerOptions.companyName}
                  />
                </div>
                <div className="col-md-6">
                  <CustomInput
                    register={register}
                    label=""
                    name="experience"
                    placeholder="Experience in Years"
                    type="number"
                    errors={errors.experience}
                    validation={registerOptions.experience}
                  />
                </div>
                <div className="col-md-6">
                  <CustomInput
                    register={register}
                    label=""
                    name="dreamCompany"
                    placeholder="Dream Company"
                    type="text"
                    errors={errors.dreamCompany}
                    validation={registerOptions.dreamCompany}
                  />
                </div>
                <div className="col-md-12 mb-3">
                  <div className="custom_lable mb-3">
                    1. Your Topic of Interest?*
                  </div>

                  <CheckBox
                    register={register}
                    name="Interest"
                    control={control}
                    dataItem={[
                      {
                        name: "softwareDevelopment",
                        label: "Software Development",
                      },
                      { name: "DataScience", label: "Data Science & ML" },
                      { name: "DataAnalytics", label: "Data Analytics" },
                      { name: "Data Engineer", label: "Data Engineer" },
                    ]}
                  />
                </div>
                <div className="col-md-12">
                  <div className="custom_lable mb-3">
                    2. Are you a College Student or Working Professional or
                    Graduated but not working?
                  </div>

                  <CheckBox
                    register={register}
                    name="areyou"
                    control={control}
                    dataItem={[
                      {
                        name: "CollegeStudent",
                        label: "College Student",
                      },
                      {
                        name: "WorkingProfessional",
                        label: "Working Professional",
                      },
                      {
                        name: "Graduated",
                        label: "Graduated but not working",
                      },
                    ]}
                  />
                </div>
              </div>
              <div className=" pt-3 mb-3 text-center">
                <button
                  className="btn_primary btn_lg px-5"
                  type="submit"
                  //   onClick={() => setShowDetails(!showDetails)}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </div>
      </CustomPopup>
    </>
  );
}

export default Header;
