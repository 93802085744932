import React, { useEffect, useRef, useState } from "react";
import HomeBanner from "../../Components/homeBanner/HomeBanner";
import AboutImg from "../../assets/image/vv_about.png";
import shape1 from "../../assets/image/shape/blue.png";
import shape2 from "../../assets/image/shape/Vector.png";
import VideoImg from "../../assets/image/vv_video.png";
import Vision from "../../assets/image/vv_vision.png";
import Mission from "../../assets/image/vv_mission.png";
import stop from "../../assets/image/stop.png";
import why_choose from "../../assets/image/why_choose.png";
import traning from "../../assets/image/vv_traning.png";
import learning from "../../assets/image/vv_learning.png";
import global from "../../assets/image/vv_global.png";
import expert from "../../assets/image/vv_expert.png";
import support from "../../assets/image/vv_support.png";
import study from "../../assets/image/vv_study_abord.png";
import leaderImg from "../../assets/image/vv_leader.png";
import Tcs from "../../assets/image/tcs.png";
import Decore from "../../assets/image/shape/decore.png";
import home from "../../assets/image/shape/home.png";
import el1 from "../../assets/image/el1.png";
import el2 from "../../assets/image/el2.png";
import owl1 from "../../assets/image/owl1.png";
import owl2 from "../../assets/image/owl2.png";

import Faq from "../../Components/faq/Faq";
import Instructors from "../../Components/Instructors/Instructors";
import Testimonial from "../../Components/testimonial/Testimonial";
import SteveJobs from "../../assets/image/SteveJobs.png";
import SatyaNadella from "../../assets/image/SatyaNadella.png";
import tcs from "../../assets/image/logo/tcs.png";
import acc from "../../assets/image/logo/acc.png";
import cap from "../../assets/image/logo/cap.png";
import cog from "../../assets/image/logo/cog.png";
import dxc from "../../assets/image/logo/dxc.png";
import hcl from "../../assets/image/logo/hcl.png";
import ibm from "../../assets/image/logo/ibm.png";
import info from "../../assets/image/logo/info.png";
import tm from "../../assets/image/logo/tm.png";
import wipro from "../../assets/image/logo/wipro.png";
import t1 from "../../assets/image/logo/t1.png";
import t2 from "../../assets/image/logo/t2.png";

//
import amazon from "../../assets/image/logo/amazon.png";
import flipkart from "../../assets/image/logo/flipkart.png";
import snap from "../../assets/image/logo/snap.png";
import myntra from "../../assets/image/logo/myntra.png";
import big from "../../assets/image/logo/big.png";
import zomato from "../../assets/image/logo/zomato.png";
import swaiggy from "../../assets/image/logo/swaiggy.png";
import nykaa from "../../assets/image/logo/nykaa.png";
import paytm from "../../assets/image/logo/paytm.png";

//
import "./home.css";
import { Accordion, Col, Container, Nav, Row, Tab } from "react-bootstrap";

import Slider from "react-slick";
import { Link } from "react-router-dom";
const Home = () => {
  const itCom = [tcs, info, wipro, hcl, acc, ibm, cog, dxc, cap, tm];
  const eCom = [
    snap,
    myntra,
    flipkart,
    amazon,
    big,
    zomato,
    swaiggy,
    nykaa,
    paytm,
  ];
  useEffect(() => {
    document.title = "NextGen 5.0 - Transform Your Future with vvGrad";
  }, []);
  const [activeTab, setActiveTab] = useState("tab1");

  const handleSelect = (key) => setActiveTab(key);

  const InspirationalQuotes = [
    {
      img: SteveJobs,
      name: "Steve Jobs",
      post: "Co-Founder",
      company: "Apple",
      content: `"It's not about money. It's about the people you have and how you're led."`,
    },
    {
      img: SatyaNadella,
      name: "Satya Nadella",
      post: "CEO",
      company: "Microsoft",
      content: `"Our industry does not respect tradition. It only respects innovation."`,
    },
  ];

  const BusinessAnalyst = [
    {
      img: t1,
      name: "Sneha Arora",
      post: "Business Analyst (Hired by Deloitte)",
      company: "",
      content: `"VV Grad gave me the tools to transition into a business analyst role seamlessly. The emphasis on data visualization and stakeholder management was exactly what I needed to excel in my job."`,
    },
    {
      img: t2,
      name: "Arjun Das",
      post: "Business Analyst (Hired by EY)",
      company: "",
      content: `"The Business Analyst track at VV Grad taught me how to bridge the gap between business needs and technical solutions. The real-world project experience was invaluable during interviews."`,
    },
  ];

  const DeveloperTestimonials = [
    {
      img: t1,
      name: "Priya Iyer",
      post: "Software Developer (Hired by Infosys)",
      company: "",
      content: `"Learning full-stack development at VV Grad helped me secure a role as a software developer. The detailed curriculum and hands-on coding sessions made all the difference."`,
    },
    {
      img: t2,
      name: "Rohan Mehta",
      post: "Full Stack Developer (Hired by Wipro)",
      company: "",
      content: `"The practical focus on technologies like React.js and Node.js at VV Grad prepared me to develop scalable applications, giving me a strong start in my development career."`,
    },
  ];
  var settings2 = {
    dots: false,
    infinite: true,
    arrows: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };

  var settings = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  var settings3 = {
    dots: false,
    infinite: true,
    arrows: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          dots: true,
          arrows: false,
        },
      },
    ],
  };
  const sectionRef = useRef(null); // Reference to the section

  const handleScroll = () => {
    if (sectionRef.current) {
      sectionRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  return (
    <>
      <HomeBanner handleScroll={handleScroll} />
      <section className="one_nation">
        <div className="container">
          <div className="row flex-column-reverse-mobile">
            <div className="col-md-6">
              <div className="img">
                <img src={stop} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="content">
                <h1>Empowering Careers, Enabling Success—One Nation 5.0: </h1>
                <p>
                  Your one-stop solution for global education pathways, skill
                  development, and career readiness.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="about_vvgrad p-100">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="about_img">
                <img src={AboutImg} alt="" />

                <div className="shape">
                  <img src={shape1} alt="" />
                </div>
                <div className="vector">
                  <img src={shape2} alt="" />
                </div>
                <div className="video_play">
                  <img src={VideoImg} alt="" />
                  <i class="fas fa-play"></i>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="content">
                <div className="pre_heading">About vvGrad</div>
                <h1 className="main_heading mt-3 mb-3">
                  {" "}
                  Who <span className="secondary_text">We Are</span>
                </h1>
                <p className="main_text">
                  At vvGrad, we are passionate about empowering individuals to
                  achieve their career dreams. With expert-led programs, global
                  education opportunities, and tailored upskilling pathways, we
                  bridge the gap between academic learning and industry needs.
                </p>

                <p className="main_text mt-3">
                  Our training programs are delivered by industry leaders from
                  top companies and prestigious IIT alumni, ensuring you gain
                  cutting-edge knowledge and practical skills to succeed in the
                  real world.
                </p>

                <h6 className="sub_heading mt-3">
                  Join us on your journey to success!
                </h6>

                <button className="btn_primary mt-3">
                  Join Now <i class="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="our_company pb-5">
        <div className="container">
          <div className="our_wrapper">
            <div className="our_vision">
              <div className="title">
                <div className="title_img">
                  <img src={Vision} alt="" />
                </div>
                <h6 className="main_heading">Vision</h6>
              </div>
              <div className="content">
                <p className="main_text mt-3">
                  To empower every learner with global opportunities,
                  future-ready skills, and pathways to success.
                </p>
              </div>
            </div>
            <div className="our_mission">
              <div className="title">
                <div className="title_img">
                  <img src={Mission} alt="" />
                </div>
                <h6 className="main_heading">Mission</h6>
              </div>
              <div className="content">
                <p className="main_text mt-3">
                  At vvGrad, we are on a mission to bridge the gap between
                  education and industry by providing world-class training,
                  global education opportunities, and career support. We equip
                  learners with industry-specific skills, global exposure, and
                  mentorship from top companies, industry experts, and IIT
                  alumni, helping them excel in their professional journeys.
                </p>
              </div>
            </div>

            <img src={Decore} alt="" className="shape_1" />
            <img src={shape1} alt="" className="shape_2" />
            <img src={shape1} alt="" className="shape_3" />
          </div>
        </div>
      </section>

      <section ref={sectionRef} id="offerings" className="offerings p-70">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-12 col-md-7 text-center">
              <div className="pre_heading mx-auto mb-3 ">Offerings</div>
              <div className="main_heading">
                Our <span className="secondary_text">Core Offerings</span>
              </div>
              <div className="second_heading mb-5 mt-0 mt-md-3">
                {" "}
                <span className="secondary_text fw-normal">
                  Skill Development
                </span>{" "}
                Programs
              </div>
            </div>
          </div>

          {/* <div className="row ">
            <div className="col-md-6">
              <div className="content">
                <ul>
                  <li>
                    <i class="far fa-check-circle"></i>
                    <div className="main_text">
                      vvGrad Nxt Gen 5.0: Skill development for post-12th to
                      3rd-year undergraduate students.
                    </div>
                  </li>
                  <li className="ms-5">
                    <i class="far fa-check-circle"></i>
                    <div className="main_text">
                      vvGrad Talent Hub 5.0: For fresh graduates ready to
                      kickstart their IT careers.
                    </div>
                  </li>
                  <li>
                    <i class="far fa-check-circle"></i>
                    <div className="main_text">
                      vvGrad Skill Sprint 5.0: Upskilling programs for working
                      professionals and executives, in collaboration with top
                      industry leaders.
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="offerings_img">
                <div className="text-center">
                  <img src={excelent} alt="" />
                </div>
                <img src={shape2} alt="" className="shape_1" />
                <img src={shape1} alt="" className="shape_2" />
                <img src={home} alt="" className="shape_3" />
              </div>
            </div>
          </div> */}

          <div className="row">
            <div className="col-md-4 mb-4">
              <div className="offering_box nxt_gen">
                <h2 className="sub_heading mb-3">Nxt Gen</h2>
                <p className="main_text mb-4">
                  vvGrad Nxt Gen 5.0: Skill development for post-12th to
                  3rd-year undergraduate students.
                </p>

                <Link to="NextGen-Academy" className="btn_border">
                  Learn More <i class="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="offering_box talent_hub">
                <h2 className="sub_heading mb-3">Talent Hub</h2>
                <p className="main_text mb-4">
                  vvGrad Talent Hub 5.0: For fresh graduates ready to kickstart
                  their IT careers.
                </p>

                <Link to="talent-hub" className="btn_border">
                  Learn More <i class="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
            <div className="col-md-4 mb-4">
              <div className="offering_box skill_sprint">
                <h2 className="sub_heading mb-3">Skill Sprint</h2>
                <p className="main_text mb-4">
                  vvGrad Skill Sprint 5.0: Upskilling programs for working
                  professionals and executives, in collaboration with top
                  industry leaders.
                </p>

                <Link to="skill-sprint" className="btn_border">
                  Learn More <i class="fas fa-arrow-right"></i>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="study_abord">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="study_abord_img">
                <img src={study} alt="" />
              </div>
            </div>
            <div className="col-md-6">
              <div className="content">
                <h5 className="second_heading">Study Abroad Programs</h5>
                <h5 className="sub_heading">
                  Explore global education opportunities with vvGrad's expert
                  guidance for:
                </h5>
                <ul>
                  <li>
                    <i class="far fa-check-circle"></i>
                    <div className="main_text">Destination Selection</div>
                  </li>
                  <li>
                    <i class="far fa-check-circle"></i>
                    <div className="main_text">Course Finding</div>
                  </li>
                  <li>
                    <i class="far fa-check-circle"></i>
                    <div className="main_text">Visa Assistance</div>
                  </li>
                  <li>
                    <i class="far fa-check-circle"></i>
                    <div className="main_text">
                      Test Preparations (IELTS, GRE, TOEFL, etc.)
                    </div>
                  </li>
                  <li>
                    <i class="far fa-check-circle"></i>
                    <div className="main_text">
                      End-to-End Application Support
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="career p-100">
        <div className="container">
          <div className="row justify-content-center mb-5">
            <div className="col-12 col-md-7 text-center">
              <div className="pre_heading mx-auto mb-3 ">Career</div>
              <div className="main_heading">
                Career Preparation{" "}
                <span className="secondary_text">Programs</span>
              </div>
            </div>
          </div>
          <div className="row gx-5">
            <div className="col-md-6 mb-4">
              <div className="content">
                <div className="main_heading mb-3">
                  {" "}
                  <span className="secondary_text">vvPrepX</span>{" "}
                </div>
                <div className="main_text">
                  Intensive training to prepare freshers for company exams and
                  secure placements.
                </div>
                <button className="btn_border mt-4">
                  Learn More <i class="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>
            <div className="col-md-6 mb-4">
              <div className="content">
                <div className="main_heading mb-3">
                  {" "}
                  <span className="secondary_text">vvJobla</span>{" "}
                </div>
                <div className="main_text">
                  An ATS-enabled platform to simplify job applications and help
                  candidates land their dream jobs.
                </div>
                <button className="btn_border mt-4">
                  Learn More <i class="fas fa-arrow-right"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="why_choose p-100">
        <div className="container">
          <div className="row justify-content-center mb-5 pb-5">
            <div className="col-12 col-md-7 text-center">
              <div className="main_heading">
                <span className="secondary_text"> Why Choose</span> Us ?
              </div>
            </div>
          </div>
        </div>
        <div className="why_choose_wrapper">
          <div className="container">
            <div className="row ">
              <div className="col-md-6">
                <div className="why_choose_img">
                  <div className="text-center">
                    <img src={why_choose} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="content">
                  <ul>
                    <li>
                      <img src={traning} alt="" />
                      <div>
                        <div className="sub_heading">
                          Training by Top Companies and IIT Alumni
                        </div>
                        <div className="main_text">
                          Learn from the best minds in the industry.
                        </div>
                      </div>
                    </li>
                    <li>
                      <img src={learning} alt="" />
                      <div>
                        <div className="sub_heading">
                          Industry-Focused Learning
                        </div>
                        <div className="main_text">
                          Programs tailored to real-world industry demands.
                        </div>
                      </div>
                    </li>
                    <li>
                      <img src={global} alt="" />
                      <div>
                        <div className="sub_heading">Global Opportunities</div>
                        <div className="main_text">
                          Access to world-class universities and courses abroad.
                        </div>
                      </div>
                    </li>
                    <li>
                      <img src={expert} alt="" />
                      <div>
                        <div className="sub_heading">Expert Mentors</div>
                        <div className="main_text">
                          Guidance from industry leaders and education
                          professionals.
                        </div>
                      </div>
                    </li>
                    <li>
                      <img src={support} alt="" />
                      <div>
                        <div className="sub_heading">End-to-End Support</div>
                        <div className="main_text">
                          From skill development to placements and career
                          growth.
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <div className="leaders p-100">
        <div className="container">
          <div className="row justify-content-center mb-5 ">
            <div className="col-12 col-md-7 text-center">
              <div className="pre_heading mx-auto mb-3 ">
                Inspirational Quotes
              </div>
              <div className="main_heading">
                Be Inspired by
                <span className="secondary_text"> Visionary Leaders</span>
              </div>
            </div>
          </div>

          <Slider {...settings2}>
            {InspirationalQuotes.map((item) => {
              return (
                <div className="slider_wrapper py-3">
                  <div className="leader_box">
                    <div className="leader_img">
                      <img src={item.img} alt="" />
                    </div>
                    <div className="leader_content">
                      <p>{item.content}</p>
                      <div className="sub_heading">{item.name}</div>
                      <div className="main_text">
                        {item.company} {item.post && <>, {item.post}</>}
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </div>

      <section className="top_company  p-100 ">
        <div className="container">
          <div className="row justify-content-center mb-3 pb-4">
            <div className="col-md-10 text-center">
              <h1 className="main_heading mt-3">Top Companies Hiring</h1>
              <h1 className="main_heading mt-2 mb-3">
                <span className="secondary_text">
                  {" "}
                  vvGrad Learners Across Industries
                </span>
              </h1>
            </div>
          </div>
          <Tab.Container
            defaultActiveKey="tab1"
            activeKey={activeTab}
            onSelect={handleSelect}
          >
            {/* Left-Side Tabs */}
            <div className="tabs-nav">
              <Nav variant="pills" className="">
                <Nav.Item>
                  <Nav.Link eventKey="tab1">
                    <h4>Information Technology (IT)</h4>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab2">
                    {" "}
                    <h4>E-Commerce</h4>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab3">
                    {" "}
                    <h4>Financial Services (Fintech)</h4>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab4">
                    {" "}
                    <h4>Healthcare & Pharmaceuticals</h4>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab5">
                    {" "}
                    <h4>Telecommunications</h4>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab6">
                    {" "}
                    <h4>Startups & Tech Giants</h4>
                  </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                  <Nav.Link eventKey="tab7">
                    {" "}
                    <h4>Automotive & Manufacturing</h4>
                  </Nav.Link>
                </Nav.Item>
              </Nav>
            </div>
            <Row>
              {/* Right-Side Content */}
              <Col md={12} className="tabs-content">
                <Tab.Content>
                  <Tab.Pane eventKey="tab1">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="images_box">
                          {itCom?.map((item) => {
                            return (
                              <>
                                <div className="inner_box">
                                  <img src={item} alt="" />
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab2">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="images_box">
                          {eCom?.map((item) => {
                            return (
                              <>
                                <div className="inner_box">
                                  <img src={item} alt="" />
                                </div>
                              </>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab3">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="images_box">
                          <div className="inner_box">
                            <img src={Tcs} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab4">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="images_box">
                          <div className="inner_box">
                            <img src={Tcs} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab5">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="images_box">
                          <div className="inner_box">
                            <img src={Tcs} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab6">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="images_box">
                          <div className="inner_box">
                            <img src={Tcs} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="tab7">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="images_box">
                          <div className="inner_box">
                            <img src={Tcs} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </div>
      </section>

      <div className="achievers p-100">
        <div className="container">
          <div className="row justify-content-center mb-5 ">
            <div className="col-12 col-md-7 text-center">
              <div className="pre_heading mx-auto mb-3 ">Success Stories</div>
              <div className="main_heading">
                Hear From
                <span className="secondary_text"> Our Achievers</span>
              </div>
              <div className="second_heading mt-0 mt-md-4">
                Business Analyst Testimonials
              </div>
            </div>
          </div>

          <div className="row">
            <Slider {...settings}>
              {BusinessAnalyst?.map((item) => {
                return (
                  <>
                    <div className="achiver_box">
                      <div className="rating">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <span className="main_text ms-2">48</span>
                      </div>
                      <p className="main_text mb-3">{item?.content}</p>

                      <div className="author">
                        <div className="author_img">
                          <img src={item?.img} />
                        </div>
                        <div className="author_content">
                          <h3 className="sub_heading">{item?.name}</h3>
                          <span className="main_text">{item?.post}</span>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </Slider>
          </div>
          <div className="row justify-content-center mb-5 ">
            <div className="col-12 col-md-7 text-center">
              <div className="second_heading mt-5">
                Software Developer Testimonials
              </div>
            </div>
          </div>

          <div className="row">
            <Slider {...settings3}>
              {DeveloperTestimonials?.map((item) => {
                return (
                  <>
                    <div className="achiver_box">
                      <div className="rating">
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <i class="fas fa-star"></i>
                        <span className="main_text ms-2">48</span>
                      </div>
                      <p className="main_text mb-3">{item?.content}</p>

                      <div className="author">
                        <div className="author_img">
                          <img src={item?.img} alt="" />
                        </div>
                        <div className="author_content">
                          <h3 className="sub_heading">{item?.name}</h3>
                          <span className="main_text">{item?.post}</span>
                        </div>
                      </div>
                    </div>
                  </>
                );
              })}
            </Slider>
          </div>

          <div className="w-100 mt-5">
            <button className="btn_border mx-auto">
              Read More Success Stories
              <i class="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
      </div>
      <div className="success p-100">
        <div className="container">
          <div className="content">
            <div className="main_heading mb-2 secondary_text">
              Ready to Transform Your Career?
            </div>
            <div className="main_heading mb-4 ">with vv Grad today!</div>
            <div className="buttons d-flex align-items-center justify-content-center">
              <div className="btn_border me-3">
                <i className="fas fa-phone-volume ms-0 me-2"></i>
                +91 8019626969
              </div>
              <div className="btn_border me-3">
                <i className="fas fa-envelope ms-0 me-2 "></i>
                info@vvgrad.com
              </div>
              <div className="btn_border">
                <i className="fas fa-globe ms-0 me-2"></i>
                www.vvgrad.com
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="get_touch p-100">
        <div className="shape1">
          <img src={owl1} alt="" />
        </div>
        <div className="shape2">
          <img src={owl2} alt="" />
        </div>
        <div className="shape3">
          <img src={el1} alt="" />
        </div>
        <div className="shape4">
          <img src={el2} alt="" />
        </div>
        <div className="container">
          <div className="content">
            <div className="main_heading mb-3 text_white">
              Ready to Transform Your Career?
            </div>
            <div className="main_text mb-4 text_white">
              Start your journey with vvGrad today and unlock a world of
              opportunities.
            </div>
            <div className="buttons d-flex align-items-center justify-content-center">
              <div className="btn_primary me-3">
                Join Now
                <i class="fas fa-arrow-right"></i>
              </div>
              <div className="btn_white me-3">
                Speak to Our Experts
                <i class="fas fa-arrow-right"></i>
              </div>
              <div className="btn_primary ">
                Explore Programs
                <i class="fas fa-arrow-right"></i>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <NewsLetter /> */}
      {/* <Testimonial />
      <div className="p-120">
        <Instructors />
      </div> */}

      {/* <Faq /> */}
      {/* <Member /> */}
    </>
  );
};

export default Home;
